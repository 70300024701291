// Generated by Framer (50a537b)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Desktop3 from "./dDkbc4w4y";
const Desktop3Fonts = getFonts(Desktop3);
const MotionDivWithFX = withFX(motion.div);

const serializationHash = "framer-adrkt"

const variantClassNames = {F3bYQ1tFe: "framer-v-fooynr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({author, cargo, date, height, id, image, scrollSection, text, width, ...props}) => { return {...props, EgDx7dhd7: cargo ?? props.EgDx7dhd7 ?? "Zuck B", eXPCI3kD4: date ?? props.eXPCI3kD4 ?? "June 22, 2023", nzOMtkGYd: author ?? props.nzOMtkGYd ?? "Zuck B", TaljMg6Ko: text ?? props.TaljMg6Ko ?? "Our team collaboration has improved significantly since we adopted Ruya. The real-time messaging feature ensure everyone stays on the same page. ", tKomGe5uR: image ?? props.tKomGe5uR ?? {src: "https://framerusercontent.com/images/Y9KmJAQ4w53hsc4jJojfokLZ7D8.jpg?scale-down-to=512", srcSet: "https://framerusercontent.com/images/Y9KmJAQ4w53hsc4jJojfokLZ7D8.jpg?scale-down-to=1024 682w,https://framerusercontent.com/images/Y9KmJAQ4w53hsc4jJojfokLZ7D8.jpg?scale-down-to=2048 1365w,https://framerusercontent.com/images/Y9KmJAQ4w53hsc4jJojfokLZ7D8.jpg 2662w"}, w386v2jrW: scrollSection ?? props.w386v2jrW} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;scrollSection?: React.MutableRefObject<HTMLElement>;text?: string;author?: string;date?: string;image?: {src: string; srcSet?: string};cargo?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, w386v2jrW, TaljMg6Ko, nzOMtkGYd, eXPCI3kD4, tKomGe5uR, EgDx7dhd7, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "F3bYQ1tFe", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__spring={{damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}} __framer__styleTransformEffectEnabled __framer__transformTargets={[{target: {opacity: 1, rotate: -20, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 800}}, {ref: w386v2jrW, target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}}]} __framer__transformTrigger={"onScrollTarget"} __framer__transformViewportThreshold={1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-fooynr", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"F3bYQ1tFe"} ref={ref ?? ref1} style={{backgroundColor: "rgb(6, 5, 30)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}}><ComponentViewportProvider height={225} y={((componentViewport?.y || 0) + 0 + 0)}><motion.div className={"framer-1gqx47f-container"} layoutDependency={layoutDependency} layoutId={"YnWfsBtw5-container"}><Desktop3 EmHRTmkY_={toResponsiveImage(tKomGe5uR)} height={"100%"} HIIDZ15of={eXPCI3kD4} id={"YnWfsBtw5"} layoutId={"YnWfsBtw5"} pTmwiMxst={TaljMg6Ko} RslaSRm91={nzOMtkGYd} width={"100%"} xcj0VakMf={EgDx7dhd7}/></motion.div></ComponentViewportProvider></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-adrkt.framer-15h9bjo, .framer-adrkt .framer-15h9bjo { display: block; }", ".framer-adrkt.framer-fooynr { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 400px; will-change: var(--framer-will-change-override, transform); }", ".framer-adrkt .framer-1gqx47f-container { flex: none; height: auto; position: relative; width: auto; z-index: 0; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-adrkt.framer-fooynr { gap: 0px; } .framer-adrkt.framer-fooynr > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } .framer-adrkt.framer-fooynr > :first-child { margin-top: 0px; } .framer-adrkt.framer-fooynr > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 225
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"w386v2jrW":"scrollSection","TaljMg6Ko":"text","nzOMtkGYd":"author","eXPCI3kD4":"date","tKomGe5uR":"image","EgDx7dhd7":"cargo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerI9S7fPQ4g: React.ComponentType<Props> = withCSS(Component, css, "framer-adrkt") as typeof Component;
export default FramerI9S7fPQ4g;

FramerI9S7fPQ4g.displayName = "Testimonial 2";

FramerI9S7fPQ4g.defaultProps = {height: 225, width: 400};

addPropertyControls(FramerI9S7fPQ4g, {w386v2jrW: {title: "Scroll Section", type: ControlType.ScrollSectionRef}, TaljMg6Ko: {defaultValue: "Our team collaboration has improved significantly since we adopted Ruya. The real-time messaging feature ensure everyone stays on the same page. ", displayTextArea: false, title: "Text", type: ControlType.String}, nzOMtkGYd: {defaultValue: "Zuck B", displayTextArea: false, title: "Author", type: ControlType.String}, eXPCI3kD4: {defaultValue: "June 22, 2023", displayTextArea: false, title: "Date", type: ControlType.String}, tKomGe5uR: {__defaultAssetReference: "data:framer/asset-reference,Y9KmJAQ4w53hsc4jJojfokLZ7D8.jpg?originalFilename=ian-dooley-d1UPkiFd04A-unsplash+%281%29.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, EgDx7dhd7: {defaultValue: "Zuck B", displayTextArea: false, title: "Cargo", type: ControlType.String}} as any)

addFonts(FramerI9S7fPQ4g, [{explicitInter: true, fonts: []}, ...Desktop3Fonts], {supportsExplicitInterCodegen: true})